<template>
  <div id="module-list">
<v-card class="filter-section">
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold "> {{$t('common.list.filters')}} </v-card-title>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn icon @click="showFilter = !showFilter">
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row class="px-2 ma-0" v-show="showFilter">
          <!-- role filter -->

          <v-col cols="12" sm="3">
            <v-text-field v-model="filters.name" single-line dense outlined hide-details clearable
              :placeholder="$t('common.list.searchByName')"></v-text-field>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>

    <v-row class="my-0 d-flex justify-end">
      <v-col cols="12" sm="2" style="margin-right:auto" align="right">
        <v-btn color="primary" block class="" :loading="loading" :disabled="loading"
          @click="loadModules">
          <v-icon class="prefix-icon">{{ icons.mdiRefresh }}</v-icon>
          <span>
            {{$t('common.list.refresh')}}
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="2" align="right">
        <v-btn depressed block color="success" @click="setAside(null)">
          <v-icon class="prefix-icon">{{ icons.mdiPlus }}</v-icon>
          {{$t('common.list.create')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
         :headerProps="headerprops"
      >
              <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <!-- name -->
        <template #[`item.name`]="{ item }">
          <span class="text-no-wrap">{{ t(item.name) }}</span>
        </template>

        <!-- active-->
        <template #[`item.active`]="{ item }">
          <span class="text-no-wrap">{{ item.active ? 'Yes' : 'No' }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="setAside(item)">
                  <v-list-item-title>
                    <v-icon size="18" class="me-2">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="deleteModule(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <module-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="loadModules(); aside = false"
    ></module-aside>

    <!-- <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        loadCards()
        aside = false
      "
    ></form-aside> -->
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiPlus, mdiRefresh } from '@mdi/js'

import ImportDialog from '@/modules/doctor/components/ImportDialog'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import ModuleAside from './module-resource/ModuleAside.vue'
import useModuleList from './useModuleList'

export default {
  components: { ImportDialog, ModuleAside },
  setup() {
    const { tableItems, tableColumns, tableTotal, options, filters, loading, loadModules, deleteModule } = useModuleList()

    const actions = ['Edit']

    const aside = ref(false)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const resource = ref(null)
    // const categories = ref([])

    // const loadCategories = async () => {
    //   try {
    //     const {
    //       status,
    //       data: {
    //         data: { records },
    //       },
    //     } = await fetchDistributors()

    //     if (status === 200) {
    //       categories.value = records.map(record => {
    //         return { title: record.name.en, value: record.id }
    //       })
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    // loadCategories()

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      // categories,
      setAside,
      aside,
      resource,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,

      actions,

      loadModules,
      deleteModule,
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
#doctor-list {
  .search {
    max-width: 300px;
  }
}
</style>
