import { destroyModule, fetchModules } from '@/api/module/distModule'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { t } from '@/plugins/i18n'
import { reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const loading = ref(false)

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Key', value: 'key' },
    { text: 'Name', value: 'name' },
    { text: 'Active', value: 'active' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const moduleFeaturesOptions = ref([])
  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: 15,
    },
  )

  // Filter
  const filters = reactive({
    name: '',
  })

  const loadModules = async () => {
    loading.value = true

    try {
      const { data } = await fetchModules(useTableOptions(options.value, parseFilters()))

      if (data.status === 'success') {
        tableItems.value = data.data.records
        tableTotal.value = data.data.pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const mapOptions = () => {
    moduleFeaturesOptions.value = tableItems.value.map(m => ({
      ...m,
      features: m.features.map(f => ({
        label: t(f.name),
        value: f.id,
      })),
    }))
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce((a, key) => ({ ...a, [key]: filters[key] }), {})

  const deleteModule = module =>
    destroyModule(module.id)
      .then(() => {
        useNotifySuccess({ content: 'Delete Success' })
        loadModules()
      })
      .catch(useNotifyErrors)

  watch([options], () => {
    loadModules()
  })

  watch(
    () => filters.name,
    debounce(() => {
      loadModules()
    }, 300),
  )

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,

    loadModules,
    deleteModule,

    mapOptions,
    moduleFeaturesOptions,
  }
}
