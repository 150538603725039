import axios from '@axios'

const fetchModules = params => axios.get('/admin/modules', { params })
const fetchModule = id => axios.get(`/admin/modules/${id}`)
const createModule = data => axios.post(`/admin/modules`, data)
const updateModule = (id, data) => axios.put(`/admin/modules/${id}`, data)
const destroyModule = id => axios.delete(`/admin/modules/${id}`)

// const fetchLayoutPreviewPng = id => axios.get(`/admin/card-layouts/${id}/preview.png`, { responseType: 'blob' })

export { fetchModules, fetchModule, createModule, updateModule, destroyModule }
